import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/Layout";
import PriceTable from "../components/price";

const SponsorPage = () => {
  return (
    <Layout>
      <SEO title="Sponsor" />
      <div
        style={{
          marginTop: "50px"
        }}
      >
        <PriceTable />
      </div>
    </Layout>
  );
};

SponsorPage.propTypes = {};

export default SponsorPage;
