import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

const SponsorSilver = ({ data }) => {
  const {
    id,
    metadata,
    productName,
    price,
    description,
    optionName,
    options,
  } = data.contentfulProduct;

  return (
    <button
      className="snipcart-add-item pricing-one__btn border-0"
      data-item-name={productName}
      data-item-id={id}
      data-item-url="/sponsor"
      data-item-price={`${price}`}
      data-item-image={null}
      data-item-description={description.description}
      data-item-custom2-name={optionName}
      data-item-custom2-options={options}
    >
      Buy Now
    </button>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulProduct(contentful_id: { eq: "6trnuJcJwrlUHcD1TnQgw8" }) {
          id
          optionName
          options
          price
          productName
          description {
            description
          }
          category
          metadata {
            description
            keywords
            title
            url
          }
        }
      }
    `}
    render={(data) => <SponsorSilver data={data} />}
  />
);

SponsorSilver.propTypes = {};
