import React from "react";
import "./index.scss";
import SponsorSilver from "../button/sponsor/silver";
import SponsorGold from "../button/sponsor/gold";
import SponsorPlatinum from "../button/sponsor/platinum";

const PriceTable = () => {
  return (
    <section className="pricing-one">
      <div className="container">
        <div
          style={{
            marginBottom: "90px"
          }}
          className="sec-title text-center"
        >
          <h2 className="title-line ">
            YMO <br /> Sponsor Packages
          </h2>
          <span className="line-block " />
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="pricing-one__single">
              <div className="pricing-one__top">
                <h3 className="pricing-one__title">Silver</h3>
                <p className="pricing-one__price">
                  <span className="pricing-one__amount">Starting $75</span>
                </p>
              </div>
              <ul className="pricing-one__feature">
                <li className="pricing-one__feature-item">Quarter</li>
                <li className="pricing-one__feature-item">
                  Half additional $25.00
                </li>
                <li className="pricing-one__feature-item">
                  Full additional $100.00
                </li>
              </ul>
              <div className="pricing-one__bottom">
                <SponsorSilver />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pricing-one__single pricing-one__popular">
              <div class="pricing-one__popular-text">Most Popular</div>
              <div className="pricing-one__top">
                <h3 className="pricing-one__title">Gold</h3>
                <p className="pricing-one__price">
                  <span className="pricing-one__amount">$250</span>
                </p>
              </div>
              <ul className="pricing-one__feature">
                <li className="pricing-one__feature-item">
                  Fully digital exposure
                </li>
                <li className="pricing-one__feature-item">24/7 audience</li>
                <li className="pricing-one__feature-item">
                  100 potential clicks daily
                </li>
              </ul>
              <div className="pricing-one__bottom">
                <SponsorGold />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pricing-one__single">
              <div className="pricing-one__top">
                <h3 className="pricing-one__title">Platinum</h3>
                <p className="pricing-one__price">
                  <span className="pricing-one__amount">Starting $300</span>
                </p>
              </div>
              <ul className="pricing-one__feature">
                <li className="pricing-one__feature-item">
                  Quarter additional $75.00
                </li>
                <li className="pricing-one__feature-item">
                  Half additional $25.00
                </li>
                <li className="pricing-one__feature-item">
                  Full additional $100.00
                </li>
              </ul>
              <div className="pricing-one__bottom">
                <SponsorPlatinum />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceTable;
